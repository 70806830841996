import './SentryEventReport.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const className = 'c-SentryEventReport';

export type SentryEventInfo = { eventId?: string; errorCode?: string };

type Props = {
  eventInfo?: SentryEventInfo;
};

const SentryEventReport = ({ eventInfo = {} }: Props) => {
  const { t } = useTranslation();
  const { eventId, errorCode } = eventInfo;

  const [showErrorInfo, setShowErrorInfo] = useState(false);

  const toggleShow = () => {
    setShowErrorInfo(!showErrorInfo);
  };

  if (!eventId) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__desc`} onClick={toggleShow}>
        {t('error.reported', 'This error has been reported to our team.')}{' '}
        <div className={`${className}__clickable`}>
          {showErrorInfo
            ? t('error.showless', 'Show less.')
            : t('error.showmore', 'Show more.')}
        </div>
      </div>
      <div
        className={classNames(`${className}__info`, {
          [`${className}--show`]: showErrorInfo,
          [`${className}--hide`]: !showErrorInfo,
        })}
      >
        <span>
          {t(
            'error.share',
            'You can share this information with our support team if you need assistance:',
          )}
        </span>
        {eventId && <span>{`${eventId}`}</span>}
        {errorCode && <span>{`${errorCode}`}</span>}
      </div>
    </div>
  );
};

export default SentryEventReport;
