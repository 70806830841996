import { useEffect, useState } from 'react';

import { captureError } from './sentry';
import { SentryEventInfo } from './SentryEventReport/SentryEventReport';

const useErrorWatcher = (
  error: unknown,
  allowError?: (error: unknown) => boolean,
) => {
  const [sentryEventInfo, setSentryEventInfo] = useState<SentryEventInfo>();

  useEffect(() => {
    if (error && (!allowError || allowError(error))) {
      const eventInfo = captureError(error);
      setSentryEventInfo(eventInfo);
    } else {
      setSentryEventInfo(undefined);
    }
  }, [allowError, error]);

  return sentryEventInfo;
};

export default useErrorWatcher;
